
.noScrollBar {
    &::-webkit-scrollbar {
        display: none;
    }
}

.image {
    transition: transform 200ms cubic-bezier(0.23, 1, 0.32, 1);

    @media (min-width: 576px) {
        &:hover {
            transform: translate(0, -5px);
        }
    }
}

.popularProductCard {
    &:active, &:focus {
        opacity: .6;
    }
}

.button {
    @media (min-width: 576px) {
        width: 400px !important;
        left: 50%;
        bottom: 20px;
        border-radius: 50px !important;
        padding: 20px 30px !important;
        margin-left: -200px;
        box-shadow: 0 10px 20px 0 rgba(var(--cs-darker-main), 0.5) !important;

        animation: appear-scale 250ms cubic-bezier(0.23, 1, 0.32, 1);
    }

    animation: appear-from-bottom 250ms cubic-bezier(0.23, 1, 0.32, 1);
}

.productDescription {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

@keyframes appear-scale {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes appear-from-bottom {
    0% {
        transform: translate(0, 100px);
        opacity: 0;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
