.wrapper {
    max-height: 44px;
}

.input {
    height: 42px;
    outline: none;
}

.btn {
    min-width: 44px;
    min-height: 44px;
}